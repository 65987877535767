import { ModalEdit, ModalListUpdate, ModalMultiTab, ModalCreate } from './modals'

const productDetails = {
    path: '/product/details/:id',
    name: 'Product-Category-details',
    meta: {
      sideOptions: false,
      OPTIONS:{sideSelector: true, state:{name:'products',data:'productDetails'},back:'Product-Category'},
      TITLE_BAR_DATA:{
        name:{plural:'product',singular:'products'},
        buttons:[{type:'icon',name:'edit.png',pathName:'ProductEdit',userRole:'can_manage_products'}],
      },
      REQUESTS:{load:'products/loadProductInstance',loadHistory:'products/loadProductHistory',},
    
    RESPONSE_DATA_FILTER:{
      details:{
          name:'name',
          batch_code:'bar code',
          auto_track_consignment:'track consignment',
          unit_cost_price:'cost price',
          unit_minimum_selling_price:'minimum selling price',
          unit_selling_price:'selling price',
        },
        history:{ name: 'productHistory', userRole: 'can_manage_products' },
      },
    },
  
    component: () => import('../components/Details.vue'),
    children:[ModalEdit.productDetails]
}

const consignmentsDetails = {
  path: '/consignment/details/:id',
  name: 'consignments-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'consignments',data:'consignmentDetails'} },
    TITLE_BAR_DATA:{
      name:{plural:'Consignment Details',singular:'consignments'},
      buttons:[]
    },
    REQUESTS:{load:'consignments/loadConsignmentInstance'},
    RESPONSE_DATA_FILTER:{
      details:{
        reference:'reference',
        cost:'cost',
        remaining:'remaining',
        description:'description',
      },
      list:{type:'consigned_products',title:'Consigned Products',header:{name:'name',quantity_consigned:'quantity consigned'
      ,quantity_remaining:'quantity remaining',unit_cost_price:'cost price'}}, 
    },
  },

  component: () => import('../components/Details.vue'),
}

const ordersDetails = {
  
  path: '/order/details/:id',
  name: 'orders-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector:true ,state:{name:'orders',data:'orderDetails'}, back:'orders'},
    TITLE_BAR_DATA:{
      name:{plural:'Order Details',singular:'orders'},
      buttons:[
        {type:'icon',name:'edit.png',pathName:'OrderEdit',userRole:'can_manage_orders'},
        {type:'icon',name:'update-list.png',pathName:'ordersListUpdate',userRole:'can_create_orders'},
        {name:'sell',pathName:'ordersMultiTab',toggle:'is_pending', userRole:['can_make_sales','can_manage_account_tabs']},
        {name:'ready',toggle:'is_pending', not:true,action:'orders/updatePendingFalse',userRole:'can_regulate_orders'}
      ]
    },

    REQUESTS:{load:'orders/loadOrderInstance',delete:'orders/deleteOrderItem', isPolling:true},
    RESPONSE_DATA_FILTER:{
      details:{
        name:'name',
        client_phone:"client's contact",
        attendant:'attendant',
        discount:'discount',
        total:'total',
        amount_payable:'Amount Payable',
        created:'created',
        modified:'modified',
        is_pending:{substitute:'status',highlight:true,booleanValues:['Pending','Ready']},
        
      },
      list:{type:'order_items',title:'Ordered Products',header:{name:'name',quantity:'quantity',price:'price(GHC)', is_pending:'status' ,modified:'modified'}}  
    },
  },
  children:[ModalListUpdate.orders,ModalEdit.orderDetails,ModalEdit.orderItemDelete,ModalMultiTab.orders],
  component: () => import('../components/Details.vue'),
}

const salesDetails = {
  path: '/sale/details/:id',
  name: 'sales-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'sales',data:'saleDetails'} },
    TITLE_BAR_DATA:{
      name:{plural:'Sales Details',singular:'sale'},
      buttons:[]
    },
    
    REQUESTS:{load:'sales/loadSaleInstance'},
    RESPONSE_DATA_FILTER:{
      details:{
        name:'name',
        balance:'balance',
        created:'date created',
        client_phone:'client phone',
        modified:'date modified',
        attendant:'attendant',
        amount_payable: 'Amount Payable',
        amount_received: 'Amount Recieved'
      },
      list:{type:'sale_items',title:'Items in sale',header:{name:'name',quantity:'quantity',price:'price(GHC)'}}  
    },
  },

  component: () => import('../components/Details.vue'),
}

const creditsItemDetails = {
  path: '/credit/record/details/:id',
  name: 'credits-item-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'credits',data:'recordDetails'} },
    TITLE_BAR_DATA:{
      name:{plural:'Record Detail',singular:'recordDetails'},
      buttons:[]
    },

    REQUESTS:{load:'credits/loadRecordInstance'},
    RESPONSE_DATA_FILTER:{
      list:{type:'record_items',title:'Record Items',header:{name:'name',item_type:'type',price:'price',quantity:'quantity',ordered_modified:'modified'}},
      details:{
        balance:'balance',
        discount_value:'discount',
        client_phone: 'customer phone',
        description:'description',
        created:'date created',
        modified:'date modified',
        total:'Tab Total',
        value:'value'

      },
    },
  },
  component: () => import('../components/Details.vue'),
}



const creditsDetails = {
  path: '/credit/details/:id',
  name: 'credits-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'credits',data:'creditTabDetails'} },
    TITLE_BAR_DATA:{
      name:{plural:'Credit Details',singular:'credit'},
      buttons:[{name:'credit',pathName:'tabCredit',userRole:'can_create_account_tabs'},{name:'debit',pathName:'tabDebit',userRole:'can_create_account_tabs'},]
    },

    REQUESTS:{load:'credits/loadTabInstance'},
    RESPONSE_DATA_FILTER:{
      list:{type:'records',title:'Tab Items',header:{record_type:'type',value:'value(GHC)',attendant:'attendent',description:'description',modified:'modified'}},
      details:{
        name:'name',
        description:'description',
        created:'date created',
        modified:'date modified',
        total:'Tab Total',

      },
    },
  },
  children: [ModalCreate.creditTab,ModalCreate.debitTab],
  component: () => import('../components/Details.vue'),
}




const expensesDetails = {
  path: '/expense/details/:id',
  name: 'expenses-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'Expenses Details',data:'expenseDetails'} },
    TITLE_BAR_DATA:{
      name:{plural:'expenses',singular:'expense'},
      buttons:[]
    },

    REQUESTS:{load:'expenses/loadExpenseInstance'},
    RESPONSE_DATA_FILTER:{
      details:{
        name:'name',
        amount:'amount',
        created:'date created',
        modified:'date modified',
      },
    },
    
  },

  component: () => import('../components/Details.vue'),
}

const staffDetails = {
  path: '/staff/details/:id',
  name: 'staff-details',
  meta: {
    sideOptions: false,
    OPTIONS:{sideSelector: true, state:{name:'staff',data:'staffDetails'}, back:'staff' },
    TITLE_BAR_DATA:{
      name:{plural:'staff',singular:'staff'},
      buttons:[{type:'icon',name:'edit.png',pathName:'staffEdit',userRole:'can_manage_staff'},]
    },

    REQUESTS:{load:'staff/loadStaffInstance'},
    RESPONSE_DATA_FILTER:{
      details:{
        name:'name',
        username:'username',
        phone:'phone',
        user_role_name:'user role',
        description: 'description',
        created:'created',
        modified:'modified',
      },
    },
    
  },
  children: [ModalEdit.staffDetails],
  component: () => import('../components/Details.vue'),
}






export const AllDetails = [
    productDetails,
    consignmentsDetails,
    ordersDetails,
    salesDetails,
    creditsDetails,
    creditsItemDetails,
    expensesDetails,
    staffDetails,
]